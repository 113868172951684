const Header = (element) => {
	if (!element) return;
	const toggle = element.querySelector("#menuToggle");
	const mainMenu = element.querySelector("#mainMenu");
	const logo = element.querySelector("#headerLogo");
	const lines = element.querySelector("#menuLines");
	const searchToggle = element.querySelector("#searchToggle");
	const searchForm = element.querySelector("#searchForm");

	const openClass = "open";
	const menuAnimationItems = [toggle, mainMenu, logo];

	toggle &&
		toggle.addEventListener("click", (event) => {
			if (mainMenu.classList.contains("open")) {
				closeMainMenu();
			} else {
				openMainMenu();
			}
		});

	// change header appearance on scroll
	window.addEventListener("scroll", (event) => {
		if (window.pageYOffset > 100) {
			element.classList.add("collapsed");
		} else {
			element.classList.remove("collapsed");
		}
	});

	const openMainMenu = () => {
		// fix body position for scroll
		document.body.style.position = "fixed";
		document.body.style.top = `-${window.scrollY}px`;

		menuAnimationItems.forEach((item) => {
			item.classList.add(openClass);
		});
	};

	const closeMainMenu = () => {
		// fix body position for scroll
		const scrollY = document.body.style.top;
		document.body.style.position = "";
		document.body.style.top = "";
		window.scrollTo(0, parseInt(scrollY || "0") * -1);

		menuAnimationItems.forEach((item) => {
			item.classList.remove(openClass);
		});
	};
};

export default Header;
